import { useLiveQuery } from "dexie-react-hooks";
import Link from "next/link";
import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { useWindowSize } from "react-use";
import styles from "./SideMenuModal.module.scss";
import PropertySearchBar from "@/common/partials/SearchBar/PropertySearchBar";
import { MIN_PC_WIDTH } from "~/const/window";
import { FavoriteRoom } from "~/db/models/FavoriteRoom";
import { SavedSearch } from "~/db/models/SavedSearch";
import { SearchHistory } from "~/db/models/SearchHistory";
import { ViewHistory } from "~/db/models/ViewHistory";
import { addParameter, removeParameters } from "~/utils/urlUtil";

/**
 * サイドメニューコンポーネント
 *
 * クライアントサイドでのみ読み込まれるようにする。
 */
const pastCriteriaSearchButton = (
  criteria: SearchHistory | SavedSearch,
  setSideMenuIsOpen: React.Dispatch<boolean>,
) => {
  return (
    <Link
      className={`${styles.pastCriteria}`}
      onClick={() => setSideMenuIsOpen(false)}
      href={addParameter(removeParameters(criteria.path, ["o"]), "o", "arrived")}
      prefetch={false}
    >
      <span className={`${styles.text} ${styles.textOverflow}`}>
        {Object.values(criteria.conditions).join("/")}
      </span>
    </Link>
  );
};

const countLabel = (count: number) => {
  return (
    <div className={styles.countWrapper}>
      <span>{count}</span>
      <span>件</span>
    </div>
  );
};

const SideMenuModal: React.FunctionComponent<{
  sideMenuIsOpen: boolean;
  setSideMenuIsOpen: React.Dispatch<boolean>;
}> = ({ sideMenuIsOpen, setSideMenuIsOpen }) => {
  const [newestSavedSearch, setNewestSavedSearch] = useState<SavedSearch>(null);
  const [newestSearchHistory, setNewestSearchHistory] = useState<SearchHistory>(null);
  const [viewHistories, setViewHistories] = useState<ViewHistory[]>([]);

  // 最も新しい保存条件を取得
  const getNewestSavedSearch = async () => {
    setNewestSavedSearch(await SavedSearch.getNewest());
  };

  // 最も新しい検索履歴を取得
  const getNewestSearchHistory = async () => {
    setNewestSearchHistory(await SearchHistory.getNewest());
  };

  // 直近50件の閲覧履歴を取得
  const getViewHistories = async () => {
    setViewHistories(await ViewHistory.getLatest());
  };

  const favoriteCount = useLiveQuery(() => FavoriteRoom.getCount(), [], 0);
  const savedCount = useLiveQuery(() => SavedSearch.getCount(), [], 0);
  const viewHistoryCount = viewHistories.length;

  useEffect(() => {
    getNewestSavedSearch();
    getNewestSearchHistory();
    getViewHistories();
  }, [sideMenuIsOpen]);

  const { width: windowWidth } = useWindowSize();

  return (
    <Modal
      isOpen={sideMenuIsOpen}
      onRequestClose={() => setSideMenuIsOpen(false)}
      closeTimeoutMS={windowWidth > MIN_PC_WIDTH ? 0 : 500}
      style={{
        overlay: {
          backgroundColor: "rgba(0,0,10,0.5)",
          zIndex: 12,
        },
        content: {
          border: "none",
          background: "none",
        },
      }}
    >
      <div className={styles.sideMenuWrapper}>
        <div className={styles.sideMenuBody}>
          <div className={styles.sideMenuCloseIcon} onClick={() => setSideMenuIsOpen(false)}>
            ×
          </div>
          <div className={styles.sideMenuTitle}>マイページ</div>
          <ul className={styles.sideMenuContentsList}>
            <li>
              <Link
                onClick={() => setSideMenuIsOpen(false)}
                rel="nofollow"
                className={styles.sideMenuContentItemWithCount}
                href="/mypage/saved/property"
              >
                お気に入り
                {favoriteCount > 0 && countLabel(favoriteCount)}
              </Link>
            </li>
            <li>
              <Link
                onClick={() => setSideMenuIsOpen(false)}
                rel="nofollow"
                className={styles.sideMenuContentItemWithCount}
                href="/mypage/history/property"
              >
                閲覧履歴
                {viewHistoryCount > 0 && countLabel(viewHistoryCount)}
              </Link>
            </li>
            <li>
              <Link
                onClick={() => setSideMenuIsOpen(false)}
                rel="nofollow"
                className={styles.sideMenuContentItemWithCount}
                href="/mypage/saved/search"
              >
                保存した条件
                {savedCount > 0 && countLabel(savedCount)}
              </Link>
            </li>
          </ul>
          <div className={styles.sideMenuButtons}>
            <Link
              className={styles.station}
              onClick={() => setSideMenuIsOpen(false)}
              href="/condition/route/"
            >
              <span>路線・駅から探す</span>
            </Link>
            <Link
              className={styles.area}
              onClick={() => setSideMenuIsOpen(false)}
              href="/condition/region/"
            >
              <span>エリアから探す</span>
            </Link>
          </div>

          <div className={styles.searchItem}>
            <p className={styles.label}>物件名から探す</p>
            <div className={styles.item}>
              <PropertySearchBar
                type="menu"
                onSearch={() => setSideMenuIsOpen(false)}
              ></PropertySearchBar>
            </div>
          </div>

          {(newestSavedSearch || newestSearchHistory) && (
            <div className={styles.searchItem}>
              <p className={styles.label}>
                {newestSavedSearch ? "保存した条件" : "前回検索した条件"}
              </p>
              <div className={styles.item}>
                {newestSavedSearch
                  ? pastCriteriaSearchButton(newestSavedSearch, setSideMenuIsOpen)
                  : pastCriteriaSearchButton(newestSearchHistory, setSideMenuIsOpen)}
              </div>
            </div>
          )}
          <div className={styles.sideMenuTitle}>メニュー</div>
          <ul className={styles.sideMenuContentsList}>
            <li>
              <Link onClick={() => setSideMenuIsOpen(false)} href="/">
                TOP
              </Link>
            </li>
            <li>
              <Link onClick={() => setSideMenuIsOpen(false)} href="/list">
                すべての物件
              </Link>
            </li>
            <li>
              <Link onClick={() => setSideMenuIsOpen(false)} href="/about">
                エアドアについて
              </Link>
            </li>
            <li className={styles.question}>
              <Link onClick={() => setSideMenuIsOpen(false)} href="/static/qa">
                よくある質問
              </Link>
            </li>
            <li>
              <Link
                target="_blank"
                rel="nofollow noopener noreferrer"
                onClick={() => setSideMenuIsOpen(false)}
                href="https://forms.gle/km19CwSMfjPeMig67"
              >
                お問い合わせ
              </Link>
            </li>
            <li>
              <Link
                target="_blank"
                rel="nofollow noopener noreferrer"
                onClick={() => setSideMenuIsOpen(false)}
                href="https://g8djtotv5zr.typeform.com/to/CW1807Ib"
              >
                アンケートに協力
              </Link>
            </li>
            <li>
              <Link onClick={() => setSideMenuIsOpen(false)} href="/static/corporation">
                運営会社
              </Link>
            </li>
            <li>
              <Link
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.instagram.com/airdoor_jp"
              >
                公式Instagram
              </Link>
            </li>
            <li>
              <Link target="_blank" rel="noopener noreferrer" href="https://twitter.com/airdoor_jp">
                公式X
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </Modal>
  );
};

export default SideMenuModal;
