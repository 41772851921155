"use client";

import { useLiveQuery } from "dexie-react-hooks";
import Image from "next/image";
import Link from "next/link";
import { usePathname } from "next/navigation";
import { Dispatch, SetStateAction, useCallback, useContext, useEffect, useState } from "react";
import { useWindowSize } from "react-use";
import { Hamburger } from "../../common/icons/Hamburger/Hamburger";
import { HeartWithCount } from "../../common/icons/HeartWithCount/HeartWithCount";
import { History } from "../../common/icons/History/History";
import ShowAfterMounted from "../../decorator/ShowAfterMounted";
import AppStoreBanner from "../AppStoreBanner/AppStoreBanner";
import styles from "./Header.module.scss";
import SurveyModal from "@/common/modals/SurveyModal";
import SideMenuModal from "@/layouts/SideMenuModal/SideMenuModal";
import { LOCAL_STORAGE_KEYS } from "~/const/localStorage";
import { MIN_PC_WIDTH } from "~/const/window";
import { SavedConditionPopupContext, SurveyPopupContext } from "~/contexts";
import { FavoriteRoom } from "~/db/models/FavoriteRoom";

const SCROLL_HEIGHT_HEADER_SEARCH_LINK_SHOWED = 547;
const SCROLL_HEIGHT_HEADER_CATCH_SHOWED = 267;

type Props = {
  appBannerIsOpen: boolean;
  setAppBannerIsOpen: Dispatch<SetStateAction<boolean>>;
};

const Header: React.FunctionComponent<Props> = ({ appBannerIsOpen, setAppBannerIsOpen }) => {
  const pathname = usePathname();
  const [sideMenuIsOpen, setSideMenuIsOpen] = useState(false);
  const favoriteCount = useLiveQuery(() => FavoriteRoom.getCount(), [], 0);
  const { savedConditionPopupIsOpen, setSavedConditionPopupIsOpen, savedConditionCount } =
    useContext(SavedConditionPopupContext);

  const {
    surveyPopupIsOpenFromSavedCondition,
    setSurveyPopupIsOpenFromSavedCondition,
    shouldOpenSurveyPopup,
    setShouldOpenSurveyPopup,
  } = useContext(SurveyPopupContext);

  const { width: windowWidth } = useWindowSize();
  const widthSize = windowWidth;
  const isPC = widthSize > MIN_PC_WIDTH;

  const updateIsHideAppBanner = () => {
    localStorage.setItem(LOCAL_STORAGE_KEYS.IS_HIDE_APP_BANNER, "true");
    setAppBannerIsOpen(false);
  };

  const afterOpenSurveyPopup = useCallback(() => {
    setTimeout(() => {
      setSurveyPopupIsOpenFromSavedCondition(true);
      setShouldOpenSurveyPopup(false);
    }, 1000);
  }, [setSurveyPopupIsOpenFromSavedCondition, setShouldOpenSurveyPopup]);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout | null = null;

    if (isPC && savedConditionPopupIsOpen) {
      timeoutId = setTimeout(() => {
        setSavedConditionPopupIsOpen(false);

        if (shouldOpenSurveyPopup) {
          afterOpenSurveyPopup();
        }
      }, 5000);
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [
    savedConditionPopupIsOpen,
    setSavedConditionPopupIsOpen,
    isPC,
    shouldOpenSurveyPopup,
    afterOpenSurveyPopup,
  ]);

  const [scrollY, setScrollY] = useState(0);
  const handleScroll = () => {
    setScrollY(window.scrollY);
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <div className={`${styles.headerWrapper} l-basic`}>
        {appBannerIsOpen && <AppStoreBanner updateIsHideAppBanner={updateIsHideAppBanner} />}
        <div className={styles.headerBody}>
          <Link className={styles.headerLogo} aria-label="TOPへ戻る" href="/" prefetch={false} />
          {(pathname !== "/" || scrollY > SCROLL_HEIGHT_HEADER_CATCH_SHOWED) && (
            <div className={`is-pc-only ${styles.catch}`}>
              <p className={`${styles.text}`}>
                狙ったお部屋は逃さない<br></br>ダイレクト賃貸検索のエアドア
              </p>
            </div>
          )}

          <div className={styles.headerButtons}>
            {(pathname !== "/" || scrollY > SCROLL_HEIGHT_HEADER_SEARCH_LINK_SHOWED) && (
              <>
                <Link className={styles.station} href="/condition/route/">
                  <span>路線・駅から探す</span>
                </Link>
                <Link className={styles.area} href="/condition/region/">
                  <span>エリアから探す</span>
                </Link>
              </>
            )}
          </div>

          <ul className={styles.headerMenus}>
            <li className={styles.headerMenuItem}>
              <Link
                aria-label="お気に入り"
                rel="nofollow"
                className={styles.headerMenuItemLink}
                href="/mypage/saved/property"
                prefetch={false}
              >
                <span className={styles.icon}>
                  <HeartWithCount count={favoriteCount}></HeartWithCount>
                </span>
                <span className={styles.iconText}>お気に入り</span>
              </Link>
            </li>
            <li className={`${styles.headerMenuItem} ${styles.relative} is-pc-only`}>
              <Link
                aria-label="保存条件"
                rel="nofollow"
                className={styles.headerMenuItemLink}
                href="/mypage/saved/search"
                prefetch={false}
              >
                <span className={styles.icon}>
                  <History></History>
                </span>
                <span className={styles.iconText}>保存条件</span>
              </Link>
              {isPC && savedConditionPopupIsOpen && (
                <div className={styles.savedConditionPopup}>
                  <div className={styles.innerWrap}>
                    <p className={styles.title}>検索条件を保存しました</p>
                    <p
                      className={styles.description}
                    >{`現在${savedConditionCount}件が保存されています`}</p>
                    <div
                      className={styles.iconWrap}
                      onClick={() => {
                        setSavedConditionPopupIsOpen(false);
                        shouldOpenSurveyPopup && afterOpenSurveyPopup();
                      }}
                    >
                      <Image
                        width={15}
                        height={15}
                        alt="閉じる"
                        src="/static/svgs/header/close.svg"
                        unoptimized
                      />
                    </div>
                  </div>
                </div>
              )}
            </li>
            <li className={styles.headerMenuItem}>
              <button
                onClick={() => setSideMenuIsOpen(true)}
                className={styles.headerMenuItemLink}
                aria-label="メニュー"
              >
                <span className={styles.icon}>
                  <Hamburger></Hamburger>
                </span>
                <span className={styles.iconText}>メニュー</span>
              </button>
            </li>
          </ul>
        </div>
      </div>

      <ShowAfterMounted>
        <SideMenuModal
          sideMenuIsOpen={sideMenuIsOpen}
          setSideMenuIsOpen={setSideMenuIsOpen}
        ></SideMenuModal>
      </ShowAfterMounted>

      {isPC && (
        <SurveyModal
          isOpen={surveyPopupIsOpenFromSavedCondition}
          setIsOpen={setSurveyPopupIsOpenFromSavedCondition}
          fromSavedCondition
        />
      )}
    </>
  );
};

export default Header;
