import Image from "next/image";
import Link from "next/link";
import { FC } from "react";
import styles from "@/layouts/AppStoreBannerForTop/AppStoreBannerForTop.module.scss";
import appIcon from "public/static/images/icon/airdoor-app-icon.png";
import Stars from "public/static/svgs/top/rating-stars.svg";
import { APP_OPEN_LINK } from "~/const/app";

type Props = {
  updateIsHideAppBanner: () => void;
};

const AppStoreBannerForTop: FC<Props> = ({ updateIsHideAppBanner }) => (
  <div className={styles.bannerContainer}>
    <div className={styles.topContent}>
      <Image src={appIcon} alt="" width={52} height={52} />
      <div>
        <p className={styles.text}>アプリで新着物件を毎日チェック！</p>
        <div className={styles.ratingContainer}>
          <Stars />
          <span className={styles.grayText}>無料-ダウンロード</span>
        </div>
      </div>
    </div>
    <div className={styles.buttonsContainer}>
      <div className={styles.closeButton} onClick={updateIsHideAppBanner}>
        あとで
      </div>
      <Link className={styles.installLink} href={APP_OPEN_LINK} target="_blank">
        アプリを入手（無料）
      </Link>
    </div>
  </div>
);

export default AppStoreBannerForTop;
